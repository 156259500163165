<template>
    <div class="table__box--container">
        <div class="table-responsive">
            <table class="table mb-0 table-bordered table-box table-hover">
                <thead>
                    <tr>
                        <th>{{ $t('tableBox.stt') }}</th>
                        <th>{{ $t('tableBox.nameCP') }}</th>
                        <th>{{ $t('tableBox.progressStatus') }}</th>
                        <th>{{ $t('tableBox.secretariatStartDate') }}</th>
                        <th>{{ $t('tableBox.campaignEndDate') }}</th>
                        <th>{{ $t('tableBox.secretariatEndDate') }}</th>
                        <th>{{ $t('tableBox.personInCharge') }}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody v-if="$props.listData.length > 0 && !$props.isLoading">
                    <template v-for="(item, k) in $props.listData">
                        <Transition name="slide-fade__table--list" appear :key="item.id">
                            <tr @dblclick="goToView('/campaign/form/view', item)">
                                <td>
                                    {{ parseInt(($props.inforPage.page - 1) * $props.inforPage.limit) + parseInt(k + 1) }}
                                </td>
                                <td class="truncate-cell" style="width: 280px; text-align: left">
                                    <p class="truncate-cell" style="width: 280px; white-space: nowrap">
                                        {{ item.name }}
                                    </p>
                                </td>
                                <td>
                                    <div class="row-data-small">{{ renderNameProgress(item.progress_status) }}</div>
                                </td>
                                <td>
                                    <div class="row-data-small">{{ formatDateCommon(item.secretariat_start_date, 'dateTimeJP') ?? '' }}</div>
                                </td>
                                <td>
                                    <div class="row-data-small">{{ formatDateCommon(item.campaign_end_date, 'dateTimeJP') ?? '' }}</div>
                                </td>
                                <td>
                                    <div class="row-data-small">{{ formatDateCommon(item.secretariat_end_date, 'dateTimeJP') ?? '' }}</div>
                                </td>
                                <td class="truncate-cell" style="width: 250px; text-align: left">
                                    <p class="truncate-cell" style="width: 250px; white-space: nowrap">
                                        {{ item.person_in_charge?.name }}
                                    </p>
                                </td>
                                <td align="center">
                                    <div class="row-data-small">
                                        <a href="javascript:void(0)" class="text-info" @click="goToDetail('campaign/form/view', item)">{{
                                            $t('btn.detail')
                                        }}</a>
                                    </div>
                                </td>
                            </tr>
                        </Transition>
                    </template>
                </tbody>
                <tbody v-else-if="$props.isLoading">
                    <template>
                        <tr style="position: relative; height: 80px">
                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                <LoadingIcon />
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <template>
                        <Transition name="slide-fade__table--list" appear>
                            <tr style="position: relative; height: 80px">
                                <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                    <p class="mt-3 text-center">
                                        {{ $t('notificationCommon.noData') }}
                                    </p>
                                </td>
                            </tr>
                        </Transition>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { campaignStatus } from '@/config/var-common';
import { formatDateCommon } from '@/utils/format';

import LoadingIcon from '@/components/Loading/main.vue';
export default {
    components: {
        LoadingIcon
    },
    props: {
        listData: {
            type: Array,
            required: true
        },
        inforPage: {
            type: Object
        },
        isLoading: {
            type: Boolean,
            require: false,
            default: () => false
        }
    },
    data() {
        return {};
    },
    methods: {
        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        renderNameProgress(params) {
            return campaignStatus.find((item) => item.id === params)?.text;
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        goToView(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
