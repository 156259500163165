<template>
    <div>
        <div class="card">
            <!-- START CAMPAINGS -->
            <div>
                <div class="card-header">
                    <h3
                        class="mb-0 title--click title--screen"
                        @click="
                            $router.push({
                                path: '/campaign/list',
                                query: {
                                    partner_dashboard: userInfo.partner_id,
                                    progress_status: sttEvent1.toString()
                                }
                            })
                        "
                    >
                        キャンペーン一覧 ( 運用中 )
                    </h3>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0 table-bordered">
                            <thead>
                                <tr>
                                    <th>CP名</th>
                                    <th>ステータス</th>
                                    <th>事務局開始日</th>
                                    <th>CP終了日</th>
                                    <th>事務局終了日</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="listDataCPProcessing.length > 0 && !isLoadingCPProcessing">
                                <template v-for="item in listDataCPProcessing">
                                    <Transition name="slide-fade__table--list" appear :key="item.id">
                                        <tr>
                                            <td class="truncate-cell" style="width: 280px">
                                                <p class="truncate-cell" style="width: 280px; white-space: nowrap">
                                                    {{ item?.name }}
                                                </p>
                                            </td>
                                            <td>
                                                {{ renderNameProgress(item.progress_status) }}
                                            </td>
                                            <td>{{ item.secretariat_start_date }}</td>
                                            <td>{{ item.campaign_end_date }}</td>
                                            <td>{{ item.secretariat_end_date }}</td>
                                            <td align="center">
                                                <a href="javascript:void(0)" class="text-info" @click="goToDetail('campaign/form', item)">{{
                                                    $t('btn.detail')
                                                }}</a>
                                            </td>
                                        </tr>
                                    </Transition>
                                </template>
                            </tbody>
                            <tbody v-else-if="isLoadingCPProcessing">
                                <template>
                                    <tr style="position: relative; height: 80px">
                                        <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                            <div class="d-flex justify-content-center">
                                                <LoadingIcon />
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <template>
                                    <Transition name="slide-fade__table--list" appear>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.noData') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </Transition>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- END CAMPAINGS -->

            <!-- START CAMPAINGS -->
            <div>
                <div class="card-header">
                    <h3
                        class="mb-0 title--click title--screen"
                        @click="
                            $router.push({
                                path: '/campaign/list',
                                query: {
                                    partner_dashboard: userInfo.partner_id,
                                    progress_status: sttEvent2.toString()
                                }
                            })
                        "
                    >
                        キャンペーン一覧 ( 開催前 )
                    </h3>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0 table-bordered">
                            <thead>
                                <tr>
                                    <th>CP名</th>
                                    <th>ステータス</th>
                                    <th>事務局開始日</th>
                                    <th>CP終了日</th>
                                    <th>事務局終了日</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="listDataCPBeforeTheEvent.length > 0 && !isLoadingCPBeforeTheEvent">
                                <template>
                                    <tr v-for="item in listDataCPBeforeTheEvent" :key="item.id">
                                        <td class="truncate-cell" style="width: 280px">
                                            <p class="truncate-cell" style="width: 280px; white-space: nowrap">
                                                {{ item?.name }}
                                            </p>
                                        </td>
                                        <td>
                                            {{ renderNameProgress(item.progress_status) }}
                                        </td>
                                        <td>{{ item.secretariat_start_date }}</td>
                                        <td>{{ item.campaign_end_date }}</td>
                                        <td>{{ item.secretariat_end_date }}</td>
                                        <td align="center">
                                            <a href="javascript:void(0)" class="text-info" @click="goToDetail('campaign/form', item)">{{
                                                $t('btn.detail')
                                            }}</a>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else-if="isLoadingCPBeforeTheEvent">
                                <template>
                                    <tr style="position: relative; height: 80px">
                                        <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                            <div class="d-flex justify-content-center">
                                                <LoadingIcon />
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <template>
                                    <Transition name="slide-fade__table--list" appear>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.noData') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </Transition>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- END CAMPAINGS -->
        </div>
    </div>
</template>

<script>
import appConfig from '@/app.config';
import { masterMethods, campaignMethods } from '@/state/helpers';
import { state } from '@/state/modules/authfack';
import { campaignStatus } from '@/config/var-common';
import LoadingIcon from '@/components/Loading/main.vue';

export default {
    page: {
        title: 'パートナー',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon
    },
    data() {
        return {
            userInfo: state.user,
            sttEvent1: [3, 4],
            sttEvent2: [1, 2],
            //  START CAMPAINGS_PROCESSING
            processing: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCPProcessing: [],
            filters: {
                progress_status: ''
            },
            has: {
                partner: ''
            },
            isLoadingCPProcessing: false,
            // END  CAMPAINGS_PROCESSING

            //  START CAMPAINGS_BEFORE-THE-EVENT
            beforeTheEvent: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCPBeforeTheEvent: [],
            isLoadingCPBeforeTheEvent: false
            // END  CAMPAINGS_BEFORE-THE-EVENT
        };
    },
    watch: {},
    mounted() {
        this.has.partner = this.userInfo.partner_id;
        this.getListCPProcessing();
        this.getListCPBeforeTheEvent();
    },
    computed: {},
    methods: {
        ...masterMethods,
        ...campaignMethods,
        renderNameProgress(params) {
            return campaignStatus.find((item) => item.id === params)?.text;
        },
        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString(typeQ) {
            let query;
            if (typeQ === 'processing') {
                query = [`page=${this.processing.page}`, `limit=${this.processing.limit}`, `sorts[id]=desc`];
            } else if (typeQ === 'before_the_event') {
                query = [`page=${this.beforeTheEvent.page}`, `limit=${this.beforeTheEvent.limit}`, `sorts[id]=desc`];
            }

            if (this.filters) {
                for (const [key, value] of Object.entries(this.filters)) {
                    if (key && value) query.push(`filters[${key}]=${value.toString()}`);
                }
            }
            if (this.has) {
                for (const [key, value] of Object.entries(this.has)) {
                    if (key && value) query.push(`has[${key}]=${value.toString()}`);
                }
            }

            return query.join('&');
        },
        getListCPProcessing() {
            this.isLoadingCPProcessing = true;
            this.filters.progress_status = this.sttEvent1;
            let query = this.getQueryString('processing');
            this.listCampaign(query).then((data) => {
                this.listDataCPProcessing = data.data;
                this.processing.total = data._metadata.total;
                this.processing.page = data._metadata.page;
                this.processing.limit = data._metadata.limit;
                this.isLoadingCPProcessing = false;
            });
        },
        getListCPBeforeTheEvent() {
            this.isLoadingCPBeforeTheEvent = true;
            this.filters.progress_status = this.sttEvent2;
            let query = this.getQueryString('before_the_event');
            this.listCampaign(query).then((data) => {
                this.listDataCPBeforeTheEvent = data.data;
                this.beforeTheEvent.total = data._metadata.total;
                this.beforeTheEvent.page = data._metadata.page;
                this.beforeTheEvent.limit = data._metadata.limit;
                this.isLoadingCPBeforeTheEvent = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
