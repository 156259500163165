import { render, staticRenderFns } from "./pop-up-infomation.vue?vue&type=template&id=945a0b10&scoped=true"
import script from "./pop-up-infomation.vue?vue&type=script&lang=js"
export * from "./pop-up-infomation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "945a0b10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/Paldia/FE-Paldia-Dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('945a0b10')) {
      api.createRecord('945a0b10', component.options)
    } else {
      api.reload('945a0b10', component.options)
    }
    module.hot.accept("./pop-up-infomation.vue?vue&type=template&id=945a0b10&scoped=true", function () {
      api.rerender('945a0b10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/campaign/view/components/TabPrizeView/components/Infomation/pop-up-infomation.vue"
export default component.exports