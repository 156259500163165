<template>
    <div>
        <div class="card">
            <div class="form-group row align-items-center mt-3 pl-2">
                <label class="col-sm-2 mw--form" for="validationCustom06">チーム</label>
                <div class="col-sm-2">
                    <Multiselect
                        :id="`select-team-id`"
                        :value.sync="teamId"
                        :options="listDataMasterTeam"
                        :config="{
                            trackBy: 'id',
                            label: 'value',
                            isRemove: true,
                            error: false
                        }"
                    ></Multiselect>
                </div>
            </div>
            <h3 class="my-5 pl-2 title--screen">立ち上げ案件数</h3>

            <div v-for="(tb, k) in arrTable" :key="k">
                <div class="card-header">
                    <p class="mb-0">{{ tb.title }}</p>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0 table-bordered">
                            <tbody v-if="listDataNumberProject[k] && !isLoading">
                                <template>
                                    <Transition name="slide-fade__table--list" appear>
                                        <tr>
                                            <td>案件担当名</td>
                                            <td v-for="item in listDataNumberProject[k]" :key="item.id">
                                                {{ item.name }}
                                            </td>
                                            <td>平均</td>
                                        </tr>
                                    </Transition>
                                    <Transition name="slide-fade__table--list" appear>
                                        <tr>
                                            <td>案件メイン担当数</td>
                                            <td v-for="item in listDataNumberProject[k]" :key="item.id">
                                                {{ item.campaign_pic_count }}
                                            </td>
                                            <td>
                                                {{ renderAverage(listDataAverage[k]?.campaign_pic_count, listDataNumberProject[k]?.length) }}
                                            </td>
                                        </tr>
                                    </Transition>
                                    <Transition name="slide-fade__table--list" appear>
                                        <tr>
                                            <td>案件サブ１担当数</td>
                                            <td v-for="item in listDataNumberProject[k]" :key="item.id">
                                                {{ item.campaign_sub_pic1_count }}
                                            </td>
                                            <td>
                                                {{ renderAverage(listDataAverage[k]?.campaign_sub_pic1_count, listDataNumberProject[k]?.length) }}
                                            </td>
                                        </tr>
                                    </Transition>
                                    <Transition name="slide-fade__table--list" appear>
                                        <tr>
                                            <td>案件サブ２担当数</td>
                                            <td v-for="item in listDataNumberProject[k]" :key="item.id">
                                                {{ item.campaign_sub_pic2_count }}
                                            </td>
                                            <td>
                                                {{ renderAverage(listDataAverage[k]?.campaign_sub_pic2_count, listDataNumberProject[k]?.length) }}
                                            </td>
                                        </tr>
                                    </Transition>
                                    <Transition name="slide-fade__table--list" appear>
                                        <tr>
                                            <td>合計立ち上げ案件数</td>
                                            <td v-for="item in listDataNumberProject[k]" :key="item.id">
                                                {{
                                                    renderTotalProject(
                                                        item.campaign_pic_count,
                                                        item.campaign_sub_pic1_count,
                                                        item.campaign_sub_pic2_count
                                                    )
                                                }}
                                            </td>
                                            <td>{{ handleAverage(listDataNumberProject[k]) }}</td>
                                        </tr>
                                    </Transition>
                                </template>
                            </tbody>
                            <tbody v-else-if="isLoading">
                                <template>
                                    <tr style="position: relative; height: 80px">
                                        <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                            <div class="d-flex justify-content-center">
                                                <LoadingIcon />
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { keyMaster, masterMethods, dashboardMethods } from '@/state/helpers';

import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    page: {
        title: '案件数管理',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Multiselect
    },
    data() {
        return {
            listDataMasterTeam: [],
            listDataNumberProject: [],
            listDataAverage: [],
            teamId: { id: 6, value: 'Aチーム' },
            pagination: {
                page: 1,
                limit: 5,
                total: 0
            },
            filterCP: {
                progress_status: '',
                team: ''
            },
            filterTeam: {
                team: ''
            },
            progressStatus: [3, 4, 1],
            arrTable: [
                {
                    title: '●立ち上げ案件数'
                },
                {
                    title: '●運用案件数'
                },
                {
                    title: '●ほぼ案件数'
                }
            ],
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,
        ...dashboardMethods,
        getQueryString() {
            let query = [`sorts[id]=desc`];
            if (this.filterCP) {
                for (const [key, value] of Object.entries(this.filterCP)) {
                    if (key && value) query.push(`campaign[${key}]=${value.toString()}`);
                }
            }

            if (this.filterTeam) {
                for (const [key, value] of Object.entries(this.filterTeam)) {
                    if (key && value) query.push(`filters[${key}]=${value.toString()}`);
                }
            }

            return query.join('&');
        },

        getListTeams() {
            this.listMaster(keyMaster['employee.department']).then((data) => {
                let index = data.findIndex((x) => x.id == process.env.VUE_APP_INSTRUCTOR);
                if (index >= 0) this.listDataMasterTeam = data[index].data;
            });
        },

        getCallList() {
            this.isLoading = true;
            this.filterCP.team = this.filterTeam.team = this.teamId?.id;
            let promises = [];
            for (let i = 0; i < this.progressStatus.length; i++) {
                this.filterCP.progress_status = this.progressStatus[i];
                let query = this.getQueryString();
                let promise = this.listCountEmployeeDashboard(query).then((data) => {
                    return data.data;
                });
                promises.push(promise);
            }
            Promise.all(promises).then((results) => {
                this.isLoading = false;
                this.listDataNumberProject = results.filter((result) => result !== null);
                let result = this.listDataNumberProject.map((subArray) =>
                    subArray.reduce(
                        (sum, obj) => ({
                            campaign_pic_count: sum.campaign_pic_count + obj.campaign_pic_count,
                            campaign_sub_pic1_count: sum.campaign_sub_pic1_count + obj.campaign_sub_pic1_count,
                            campaign_sub_pic2_count: sum.campaign_sub_pic2_count + obj.campaign_sub_pic2_count
                        }),
                        {
                            campaign_pic_count: 0,
                            campaign_sub_pic1_count: 0,
                            campaign_sub_pic2_count: 0
                        }
                    )
                );

                this.listDataAverage = result;
            });
        },

        renderTotalProject(numb1, numb2, numb3) {
            return parseInt(numb1) + parseInt(numb2) + parseInt(numb3);
        },
        renderAverage(item, length) {
            let result = parseInt(item) / parseInt(length);
            if (result === 0) return result;
            return result.toFixed(2);
        },
        handleAverage(params) {
            // Tính tổng của từng trường
            if (params) {
                let sumA = params.reduce((total, obj) => total + obj.campaign_pic_count, 0);
                let sumB = params.reduce((total, obj) => total + obj.campaign_sub_pic1_count, 0);
                let sumC = params.reduce((total, obj) => total + obj.campaign_sub_pic2_count, 0);

                let totalSum = sumA + sumB + sumC;
                let totalCount = params.length;
                let averageTotal = totalSum / totalCount;
                if (averageTotal === 0) return averageTotal;
                return averageTotal.toFixed(2);
            }
        }
    },
    watch: {
        teamId() {
            this.getCallList();
        }
    },
    mounted() {
        this.getListTeams();
        this.getCallList();
    }
};
</script>

<style lang="scss" scoped></style>
