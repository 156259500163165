<template>
    <div>
        <div class="card">
            <!-- START CAMPAINGS -->
            <div>
                <div class="card-header">
                    <h3
                        class="mb-0 title--click title--screen"
                        @click="
                            $router.push({
                                path: '/campaign/list',
                                query: { is_approved: 0 }
                            })
                        "
                    >
                        承認待ちキャンペーン
                    </h3>
                </div>
                <div class="card-body" v-if="listDataCampain.length && !isLoading">
                    <TableBox :list-data="listDataCampain" :infor-page="paginationCP" />
                </div>
                <div v-else-if="isLoading" class="card-body d-flex justify-content-center align-items-center">
                    <LoadingIcon />
                </div>
                <div v-else class="card-body">
                    <Transition name="slide-fade__table--list" appear>
                        <p class="mt-3 text-center">
                            {{ $t('notificationCommon.noData') }}
                        </p>
                    </Transition>
                </div>
            </div>
            <!-- END CAMPAINGS -->

            <!-- START TEAMS -->
            <div>
                <div v-for="(team, k) in listDataTeamsParent.data" :key="k">
                    <div class="card-header">
                        <h3
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: { team_id: team.id, is_approved: 1 }
                                })
                            "
                        >
                            {{ team.value }}
                        </h3>
                    </div>
                    <div class="card-body" v-if="listDataTeamChild[k] && !isLoadingTeam">
                        <TableBox :list-data="listDataTeamChild[k]" :infor-page="paginationTeam" />
                    </div>
                    <div v-else-if="isLoadingTeam" class="card-body d-flex justify-content-center align-items-center">
                        <LoadingIcon />
                    </div>
                    <div v-else class="card-body">
                        <Transition name="slide-fade__table--list" appear>
                            <p class="mt-3 text-center">
                                {{ $t('notificationCommon.noData') }}
                            </p>
                        </Transition>
                    </div>
                </div>
            </div>
            <!-- END TEAMS -->
        </div>
    </div>
</template>

<script>
import { masterMethods, keyMaster, campaignMethods } from '@/state/helpers';

import appConfig from '@/app.config';
import TableBox from '@/views/pages/dashboard/Tablebox/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
export default {
    page: {
        title: '管理者',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        TableBox,
        LoadingIcon
    },
    data() {
        return {
            //  START CAMPAINGS
            paginationCP: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCampain: [],
            filterCP: {
                is_approved: '',
                team: ''
            },
            emptyDataCP: false,
            isLoading: false,
            // END  CAMPAINGS

            //  START TEAMS
            listDataTeamsParent: [],
            listDataTeamChild: [],
            paginationTeam: {
                total: 0,
                pageTeam: 1,
                limitTeam: 10
            },
            isLoadingTeam: false
            //  END TEAMS
        };
    },
    watch: {},
    mounted() {
        this.getListCPAwaitApproval();
        this.getListTeams();
    },
    computed: {},
    methods: {
        ...masterMethods,
        ...campaignMethods,
        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.paginationCP.page}`, `limit=${this.paginationCP.limit}`, `sorts[id]=desc`];

            if (this.filterCP) {
                for (const [key, value] of Object.entries(this.filterCP)) {
                    if (key && value) query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                }
            }

            return query.join('&');
        },
        getListCPAwaitApproval() {
            this.isLoading = true;
            this.filterCP.is_approved = '0';
            let query = this.getQueryString();
            this.listCampaign(query).then((data) => {
                this.isLoading = false;
                this.listDataCampain = data.data;
                this.paginationCP.total = data._metadata.total;
                this.paginationCP.page = data._metadata.page;
                this.paginationCP.limit = data._metadata.limit;
            });
        },

        getListTeams() {
            this.isLoadingTeam = true;
            this.filterCP.is_approved = 1;
            this.listMaster(keyMaster['employee.department']).then((data) => {
                if (!data.length) return;
                const mapIdTeam = data.find((item) => item.id === 5);
                this.listDataTeamsParent = mapIdTeam;
                if (!mapIdTeam.data.length) return;
                let promises = [];
                for (let i = 0; i < mapIdTeam.data.length; i++) {
                    this.filterCP.team = mapIdTeam.data[i].id;
                    let query = this.getQueryString();
                    let promise = this.listCampaign(query).then((data) => {
                        this.paginationTeam.total = data._metadata.total;
                        this.paginationTeam.page = data._metadata.page;
                        this.paginationTeam.limit = data._metadata.limit;
                        return data.data;
                    });
                    promises.push(promise);
                }
                Promise.all(promises).then((results) => {
                    this.isLoadingTeam = false;
                    this.listDataTeamChild = results.filter((result) => result !== null);
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
